import React,{useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";

import Typography from "@material-ui/core/Typography";
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import { blue } from '@material-ui/core/colors';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import CardMedia3 from "./CardMedia3";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Paper from '@material-ui/core/Paper';
import {useParams,useNavigate,Navigate} from "react-router-dom";
import base64 from 'react-native-base64'
//import waveImg from "./wave.png";
import Divider from "@material-ui/core/Divider";

import axios from 'axios';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  item: {
    border:"1px solid gray",
	borderRadius:"5%"
  }
}));

  function MyDialog(props) {
	  const { onClose, selectedValue, open,token,seggio,add,changeConsole,id_sindaco,changeSelected,changeSelectedBySesso } = props;
	  const [counterPref,setCounterPref] = useState(0);
	  const [console,setConsole] = useState("");
	  const [prefAdded,setPrefAdded] = useState(false);
	  const [hideSesso,setHideSesso] = useState("");
	  const classes = useStyles();

	  const handleClose = () => {
		//  alert(1);
		if(prefAdded){
			setPrefAdded(false);
			changeConsole(console);
			setConsole("");
			setCounterPref(0);
			setHideSesso("");
			onClose(selectedValue);
		}else{
			if(add)
				alert("Selezionare almeno una preferenza");
			else if(window.confirm("Sicuro di chiudere senza eliminare preferenze?")){
				setPrefAdded(false);
				changeConsole(console);
				setConsole("");
				setCounterPref(0);
				onClose(selectedValue);
			}
		}
	  };

	
	  
	  const handleAddConsigliere = (id, name,sesso,token,seggio) => 
	  {
		  
		  
	//	 const { consigliereScelto } = this.state;
		  Promise.all([axios.post("https://www.scrutiniolive.it/business/addPreferenza.php",  JSON.stringify({id:id,token:token,seggio:seggio}), 
			{ headers: { "Content-Type": "application/json; charset=UTF-8" },params: { id:id,token:token,seggio:seggio},async: false
			  //Add userID as a param 
			})
			  .then(
			(result) => {
			//   console.log(result.data);
			  setConsole("Preferenza aggiunta per "+name);
			  setPrefAdded(true);
			  if(counterPref==0){
				  if(name.toLowerCase().includes("senza preferenza")){
					setCounterPref(0);
				//	setConsole(console + " e "+name);
					changeConsole("Preferenza aggiunta per "+name);
					setConsole("");
					onClose(selectedValue);
					  setPrefAdded(false);
					  setHideSesso("");
				  }else{
					setCounterPref(1);
					if(sesso=="F")
						setHideSesso("F");
						//changeSelectedBySesso(id_sindaco,"M");
					else
						setHideSesso("M");
						//changeSelectedBySesso(id_sindaco,"F");
				  }
			  }else{
				setCounterPref(0);
				setPrefAdded(false);
				setHideSesso("");
			//	setConsole(console + " e "+name);
				changeConsole(console+" e "+name);
				setConsole("");
				onClose(selectedValue);
			  }
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
				console.log(error);
			}
			)])
		
	  }
	  
	   const handleDelConsigliere = (id, name,token) => 
	  {
		  
		  
	//	 const { consigliereScelto } = this.state;
		  Promise.all([axios.post("https://www.scrutiniolive.it/business/delPreferenza.php",  JSON.stringify({id:id,token:token,seggio:seggio}), 
			{ headers: { "Content-Type": "application/json; charset=UTF-8" },params: { id:id,token:token,seggio:seggio},async: false
			  //Add userID as a param 
			})
			  .then(
			(result) => {
			//   console.log(result.data);
			  //prefAdded=true;
			  setConsole("Preferenza eliminata per "+name);
			  setPrefAdded(true);
			  if(counterPref==0){
				setCounterPref(1);
				changeSelected(id_sindaco);
			  }else{
				setCounterPref(0);
				changeConsole(console+" e "+name);
				setConsole("");
				onClose(selectedValue);
			  }
			  
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
				console.log(error);
			}
			)])
		
	  }
	  
	  

	  return (
		<Dialog 
			TransitionComponent={Transition}
			keepMounted
			onClose={handleClose} 
			open={open}
			add={add}
			fullwidth="true"
			maxWidth="md"
			aria-describedby="alert-dialog-slide-description"
		>
		  <DialogTitle>Aggiungi preferenza candidato</DialogTitle>
		  <DialogContent>
				<Typography component="h5" style={{color: 'red'}}>
								{console}
				</Typography>
				<Grid container>
				{selectedValue.map(consigliere => (
					<Grid item xs={12} md={4} key={consigliere.id}>
						
							{add ?
								<Paper variant="outlined" square elevation={3}>
									<Grid container alignItems="center" justify="center">
									
											
											 <React.Fragment>
												<Grid item>
													<Button disabled={(consigliere.nome.toLowerCase().includes("senza preferenza") && hideSesso!=="") || !(consigliere.sesso !== hideSesso || consigliere.nome.toLowerCase().includes("senza preferenza"))} onClick={()=>handleAddConsigliere(consigliere.id,consigliere.nome,consigliere.sesso,token,seggio)}>
														<React.Fragment>
														{consigliere.nome}
														<br/>
														<IconButton disabled={(consigliere.nome.toLowerCase().includes("senza preferenza") && hideSesso!=="") || !(consigliere.sesso !== hideSesso || consigliere.nome.toLowerCase().includes("senza preferenza"))}>
															<AddIcon />
														</IconButton>
														</React.Fragment>
													</Button>
												</Grid>
											</React.Fragment>
									
									</Grid>
								</Paper>
							:
								<Paper variant="outlined" square elevation={3}>
									<Grid container  alignItems="center" justify="center">
										<React.Fragment>
											<Grid item>
												<Button onClick={() =>{if (window.confirm('Confermare la cancellazione di una preferenza per '+consigliere.nome+' ?')) handleDelConsigliere(consigliere.id,consigliere.nome,token,seggio)}}>
													<React.Fragment>
														{consigliere.nome}
														<br/>
														<IconButton>
															<DeleteIcon />
														</IconButton>
													</React.Fragment>
												</Button>
											</Grid>
											
										</React.Fragment>

									</Grid>
								</Paper>
							}
						
						</Grid>
					
					))}
				</Grid>
				<Divider/>
		  </DialogContent>
		  <Button onClick={handleClose} style={{marginTop:20}}>Chiudi dettaglio</Button>
		</Dialog>
	  );
   }
function GetPrivateRappresentante3() {

    const {token} = useParams();
	const decoded = base64.decode(token);
	var tokenDec= decoded.substr(5,decoded.length-10);
    return (
        <div>
            <PrivateRappresentate3 token={tokenDec} />
        </div>
    );
}
class PrivateRappresentate3 extends React.Component {
  
   
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      candidati_sindaci: [],
	  candidati_sindaciAlfabetico: [],
      consigliereScelto:{},
	  disabled:false,
	  loading:false,
	  count:0,
	  console:"",
	  id_sindacoRef:1,
	  openDialog:false,
	  selectedValue:[],
	  addDialog:false,
	  token:"",
	  errorAut:"",
	  id_sindaco:""
    };
	
  }

  handleClose = (value) => {
    this.setState({
		openDialog:false,
		selectedValue:[]
	});
  };
  componentDidMount() {
	   Promise.all([axios.post("https://www.scrutiniolive.it/business/verifyToken.php",  JSON.stringify({token:this.props.token}), 
			{ headers: { "Content-Type": "application/json; charset=UTF-8" },params: { token:this.props.token},async: false
			  //Add userID as a param 
			})
			  .then(
			(result) => {
			//   console.log(result.data);
				if(result.data.success){
					 setInterval(() => this.getCandidati(), 1000);
				//	  setInterval(() => {this.setState({console:""})}, 6000);
					  
					  this.setState({
						token:this.props.token,
						seggio:result.data.seggio
					  });
				}else{
					this.setState({
						errorAut:"Token di autenticazione non valido",
						isLoaded:true
					});
				}
			  
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
				console.log(error);
				this.setState({loading: false});
			}
			)]);
	  
	  
//	  setInterval(() => this.getCandidati(), 1000);
//	  setInterval(() => {this.setState({console:""})}, 6000);
//	  this.getLista();
  }
  
   
  handleAdd = (id, name) => 
	  {
		  
	//	 const { consigliereScelto } = this.state;
		if(this.state.loading){
			return
		}else{
				this.setState({loading: true});
		  
		  Promise.all([axios.post("https://www.scrutiniolive.it/business/addPreferenza2.php",  JSON.stringify({id:id,token:this.state.token,seggio:this.state.seggio}), 
			{ headers: { "Content-Type": "application/json; charset=UTF-8" },params: { id:id,token:this.state.token,seggio:this.state.seggio},async: false
			  //Add userID as a param 
			})
			  .then(
			(result) => {
			//   console.log(result.data);
				
			   this.setState({
				isLoaded: true,
				loading:false,
				console: "Preferenza aggiunta per il/la candidato/a : " + name,
				id_sindaco:id
			  });
			  this.getCandidati();
			  this.getLista(id,true);
			 /* axios.post("https://www.scrutiniolive.it/business/getLista.php",  JSON.stringify({id_sindaco:"1"}), 
				{ headers: { "Content-Type": "application/json; charset=UTF-8" },params: { id_sindaco:"1" }
				  //Add userID as a param 
				})	
			  .then(
				(result) => {
				//   console.log(result.data);
				   this.setState({
					isLoaded: true,
					selectedValue: result.data.lista,
					open:true
				  });
				},
				// Note: it's important to handle errors here
				// instead of a catch() block so that we don't swallow
				// exceptions from actual bugs in components.
				(error) => {
				console.log(error);
				}
			  )*/
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
				console.log(error);
				this.setState({loading: false});
			}
			)])
		}
	  }
  getCandidati(){
	/*fetch("https://www.scrutiniolive.it/business/getCandidati.php",{
	  crossDomain:true,
	  method: 'GET',
	  headers: {'Content-Type':'application/json'},
//	  body: JSON.stringify({
//		username: user,
//		password: pass,
//	  })

	})*/
	var config = {
		  method: 'post',
		  url: 'https://www.scrutiniolive.it/business/getCandidati.php',
		   crossDomain:true,
		  headers: { 
			'Content-Type': 'application/json'
		  },
		  params: { token:this.state.token,seggio:this.state.seggio}
		};
	 axios(config)
      .then(
        (result) => {
		 Array.prototype.sortOn = function(key){
			this.sort(function(a, b){
				if(a[key]!=b[key]){
					return b[key]-a[key];
				}else{
					if(a["nome"] > b["nome"]){
						return 1;
					}else if(a["nome"] < b["nome"]){
						return -1;
					}
				}
				return 0;
			});
		}
		
		this.setState({
			candidati_sindaciAlfabetico:JSON.parse(JSON.stringify(result.data.candidati_sindaci))
		});
		this.state.candidati_sindaciAlfabetico.sortOn("nome");
		result.data.candidati_sindaci.sortOn("voti");
         this.setState({
            isLoaded: true,
            candidati_sindaci: result.data.candidati_sindaci
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
		this.setState({
            isLoaded: true,
            error
          });
        }
      )

	
  }
  
 
	addPreferenza(){
		   
	}
	
	delPreferenza(id,name,delSindaco){
		if(delSindaco){
		  axios.post("https://www.scrutiniolive.it/business/delPreferenza2.php",  JSON.stringify({id:id,token:this.state.token,seggio:this.state.seggio}), 
			{ headers: { "Content-Type": "application/json; charset=UTF-8" },params: { id:id,token:this.state.token,seggio:this.state.seggio }
			  //Add userID as a param 
			})
			  .then(
				(result) => {
				//   console.log(result.data);
				   this.setState({
					isLoaded: true,
					console: "Preferenza eliminata per il/la candidato/a : " + name,
					id_sindaco:id
				  });
				   this.getCandidati();
				   this.getLista(id,false,this.state.seggio);
				},
				// Note: it's important to handle errors here
				// instead of a catch() block so that we don't swallow
				// exceptions from actual bugs in components.
				(error) => {
				console.log(error);
				}
			  )
		}else{
			this.getLista(id,false,this.state.seggio);
		}
	}
	
	changeConsole = (console2) => {
		this.setState({
			isLoaded: true,
			console2:console2
		});
	}
	
	changeSelected = (id) => {
			  
		  axios.post("https://www.scrutiniolive.it/business/getLista.php",  JSON.stringify({id_sindaco:id,add:false,token:this.state.token,seggio:this.state.seggio}), 
			{ headers: { "Content-Type": "application/json; charset=UTF-8" },params: { id_sindaco:id,add:false,token:this.state.token,seggio:this.state.seggio}
			  //Add userID as a param 
			})
			  .then(
				(result) => {
				//   console.log(result.data);
					this.setState({
						selectedValue:result.data.lista
					});
				},
				// Note: it's important to handle errors here
				// instead of a catch() block so that we don't swallow
				// exceptions from actual bugs in components.
				(error) => {
				console.log(error);
				}
			  )

	}
	
	changeSelectedBySesso = (id,sesso) => {
			  
		  axios.post("https://www.scrutiniolive.it/business/getLista.php",  JSON.stringify({id_sindaco:id,add:true,token:this.state.token,seggio:this.state.seggio,sesso:sesso}), 
			{ headers: { "Content-Type": "application/json; charset=UTF-8" },params: { id_sindaco:id,add:true,token:this.state.token,seggio:this.state.seggio,sesso:sesso}
			  //Add userID as a param 
			})
			  .then(
				(result) => {
				//   console.log(result.data);
					this.setState({
						selectedValue:result.data.lista
					});
				},
				// Note: it's important to handle errors here
				// instead of a catch() block so that we don't swallow
				// exceptions from actual bugs in components.
				(error) => {
				console.log(error);
				}
			  )

	}
	
	getLista(id,add){
		/*fetch("https://www.scrutiniolive.it/business/getCandidati.php",{
		  crossDomain:true,
		  method: 'GET',
		  headers: {'Content-Type':'application/json'},
	//	  body: JSON.stringify({
	//		username: user,
	//		password: pass,
	//	  })

		})*/

		  
		  axios.post("https://www.scrutiniolive.it/business/getLista.php",  JSON.stringify({id_sindaco:id,add:add,token:this.state.token,seggio:this.state.seggio}), 
			{ headers: { "Content-Type": "application/json; charset=UTF-8" },params: { id_sindaco:id,add:add,token:this.state.token,seggio:this.state.seggio}
			  //Add userID as a param 
			})
			  .then(
				(result) => {
				//   console.log(result.data);
				   this.setState({
					isLoaded: true,
					selectedValue: result.data.lista,
					openDialog:true,
					addDialog:add
				  });
				},
				// Note: it's important to handle errors here
				// instead of a catch() block so that we don't swallow
				// exceptions from actual bugs in components.
				(error) => {
				console.log(error);
				}
			  )

	}

  render() {


    const { error, isLoaded, candidati_sindaci,lista,consigliereScelto,console,console2,errorAut,seggio,candidati_sindaciAlfabetico,id_sindaco  } = this.state;
	const classes = this.props;

	if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
	 if(errorAut==""){
      return (
		<div>
			
		<Grid container spacing={2} style={{marginTop:0}}>
		  
		<Grid item xs={12} md={4}>
			  <Container maxWidth="md">
					
				<Grid container spacing={1}>
				  {candidati_sindaci.map(candidato => (
					<Grid item xs={12} md={12} key={candidato.id}>
					 <CardMedia3 candidato={candidato} seggio={seggio}/>
					</Grid>
				  ))}
				</Grid>
			 </Container>
		 </Grid>
		 <Grid item xs={12} md={8}  style={{marginTop:10}}>
			 <Container maxWidth="md">
				<Grid container spacing={2} style={{marginTop:0}}>
					<Grid item xs={6} md={6}>
						<Paper variant="outlined" square elevation={3}>
							<Typography  variant="h5" component="h2" style={{textAlign: "center"}}>
							  Scrutinio elezioni amministrative 2022
							</Typography>
							<Typography variant="body2" color="textSecondary" component="p"  style={{textAlign: "center"}}>
							  Comune di Alberobello
							</Typography>
						</Paper>
						
					</Grid>
					<Grid item xs={6} md={6}>
						<Paper variant="outlined" square elevation={3}>
								<Typography  variant="h5" component="h2" style={{textAlign: "center",textStyle:"underlined"}}>
								  Seggio numero {this.state.seggio} 
								</Typography>
								
						</Paper>
					</Grid>
				</Grid>
			</Container>
             <Container maxWidth="md" style={{marginTop:10}}>
					<Paper variant="outlined" square elevation={3}>
							<Typography component="h5" style={{color: 'red'}}>
								{this.state.console}
							</Typography>
							<Typography component="h6" style={{color: 'blue'}}>
								{this.state.console2}
							</Typography>
					 </Paper>
			</Container>			   
			 <Container maxWidth="md" style={{marginTop:10}}>
					<Card>
						<CardHeader>
							<Typography component="h3">
								  Aggiungi preferenze
							</Typography>
						</CardHeader>
						<CardContent>
							<Grid container spacing={2}>
				
								{candidati_sindaciAlfabetico.map(candidato => (
								<Grid item xs={12} md={12} key={candidato.id}>
									<Grid container>
									<Grid item>{candidato.nome} </Grid>
									</Grid>
									<Grid container>
									<Grid item>
										<IconButton onClick={()=>this.handleAdd(candidato.id,candidato.nome)}>
											<AddIcon />
										</IconButton>
									</Grid>
									<Grid item>
										<IconButton onClick={() =>{if (window.confirm('Confermare la cancellazione di una preferenza per '+candidato.nome+' ? (Annullando si potrà cancellare la preferenza di un consigliere senza eliminarla al sindaco)')) this.delPreferenza(candidato.id,candidato.nome,true); else this.delPreferenza(candidato.id,candidato.nome,false); }}>
											<DeleteIcon />
										</IconButton>
									</Grid>
									</Grid>
								</Grid>
								
							  ))}
								</Grid>
						</CardContent>
					 </Card>
			 </Container>
			 <Container maxWidth="md" style={{marginTop:10,textAlign:"center"}}>
				<img src = "https://www.scrutiniolive.it/img/logo.png" width="40%" style={{borderRadius:"5%"}}/>
			 </Container>
			 </Grid>
			</Grid>
			<MyDialog
			selectedValue={this.state.selectedValue}
			open={this.state.openDialog}
			add={this.state.addDialog}
			onClose={this.handleClose}
			token={this.state.token}
			seggio={this.state.seggio}
			changeConsole = {this.changeConsole}
			changeSelected = {this.changeSelected}
			changeSelectedBySesso = {this.changeSelectedBySesso}
			id_sindaco={this.state.id_sindaco}
		  />
		 </div>
      );
	 }else{
		return(<div><Container><Typography component="h5">
									{errorAut}
								</Typography></Container></div>);
	 }
    }
  }
}
export default (GetPrivateRappresentante3)