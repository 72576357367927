import React from 'react';
import ReactDOM from 'react-dom/client';
import logo from './logo.svg';
import './App.css';
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";

import CardMedia1 from "./CardMedia";
import CardMedia2 from "./CardMedia2";
import CardMedia3 from "./CardMedia3";

import GetPrivateRappresentante from "./PrivateRappresentante";
import PrivateRappresentante2 from "./PrivateRappresentante2";
import GetPrivateRappresentante3 from "./PrivateRappresentante3";
import Countdown from 'react-countdown';


// Watch the video
// http://react.school/material-ui/card

const useStyles =(theme) => ({
  root: {
    "& > *": {
      margin: 2,
	  padding:1
    }
  }
});

/*
function RenderCard(candidato){
	const classes = useStyles();//  
return(	
	<Card maxWidth="345" key={candidato.id}>
			  <CardMedia
				height="100px"
				image={candidato.pathImg}
				title={candidato.nome}
			  />
			  <CardContent>
				<Typography gutterBottom variant="h5" component="h2">
				  Candidato
				</Typography>
				<Typography variant="body2" color="textSecondary" component="p">
				  {candidato.nome}
				</Typography>
			  </CardContent>
			</Card>
			);
}*/
class CandidatiSindaci2 extends React.Component {
  
   
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      candidati_sindaci: []
    };
	
  }
	
  componentDidMount() {
	  setInterval(() => this.getCandidati(), 1000);
    
  }
  
  getCandidati(){
	
	var config = {
		  method: 'post',
		  url: 'https://www.scrutiniolive.it/business/getCandidati2.php',
		  // crossDomain:true,
		  headers: { 
			'Content-Type': 'application/json'
		  },
		  data : {}
		};
	 axios(config).then(
        (result) => {
         this.setState({
            isLoaded: true,
            candidati_sindaci: result.data.candidati_sindaci
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
		this.setState({
            isLoaded: true,
            error
          });
        }
      )

}

  render() {


    const { error, isLoaded, candidati_sindaci } = this.state;
	const classes = this.props;

	if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
		Array.prototype.sortOn = function(key){
			this.sort(function(a, b){
				if(a[key]!=b[key]){
					return b[key]-a[key];
				}else{
					if(a["nome"] > b["nome"]){
						return 1;
					}else if(a["nome"] < b["nome"]){
						return -1;
					}
				}
				return 0;
			});
		}
		candidati_sindaci.sortOn("voti");
      return (
		<Container maxWidth="md">
				<Typography  variant="h5" component="h2"  style={{textAlign: "center",marginTop:50}}>
				  Scrutinio elezioni amministrative 
				</Typography>
				<Typography variant="body2" color="textSecondary" component="p"  style={{textAlign: "center"}}>
				  Comune di Alberobello 2022
				</Typography>
			<Grid container spacing={2}  style={{marginTop: 10}}>
			  {candidati_sindaci.map(candidato => (
				<Grid item xs={8} md={4}>
				 <CardMedia2 candidato={candidato}/>
				</Grid>
			  ))}
			</Grid>
			<Paper  style={{marginTop:50}}>
				<Typography style={{marginTop:50,textAlign:"center",fontStyle:"italic",textDecoration:"underline",padding:10}}>
					By <a href="mailto:antoniod987@gmail.com">Antonio De Giorgio</a> & Federica Mancini
				</Typography>
			</Paper>
		 </Container>
		 
      );
    }
  }
}


class CandidatiSindaci3 extends React.Component {
  
   
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      candidati_sindaci: [],
	  avvisi:"",
	  data:"",
	  ora:"",
	  mesi : ["GENNAIO","FEBBRAIO","MARZO","APRILE","MAGGIO","GIUGNO","LUGLIO","AGOSTO","SETTEMBRE","OTTOBRE","NOVEMBRE","DICEMBRE"]
    };
	
  }

  componentDidMount() {
	  setInterval(() => this.getCandidati(), 1000);
		setInterval(() => {

			var today = new Date();
			this.setState({
				data: today.getDate() + " " + this.state.mesi[today.getMonth()] + " " + today.getFullYear(),
				ora: ((today.getHours()<10) ? ("0"+today.getHours()) : today.getHours()) + ':' + ((today.getMinutes()<10) ? ("0"+today.getMinutes()) : today.getMinutes())
			});
		}, 1000);
  }
  getCandidati(){
  
	/*fetch("http://www.wineality.com/scrutinio/business/getCandidati.php",{
	  crossDomain:true,
	  method: 'GET',
	  headers: {'Content-Type':'application/json'},
//	  body: JSON.stringify({
//		username: user,
//		password: pass,
//	  })

	})*/
	var config = {
		  method: 'post',
		  url: 'https://www.scrutiniolive.it/business/getCandidati.php',
		//   crossDomain:true,
		  headers: { 
		  },
		  data : {}
		};
	 axios(config).then(
        (result) => {
         this.setState({
            isLoaded: true,
            candidati_sindaci: result.data.candidati_sindaci,
			avvisi:result.data.avvisi
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
		this.setState({
            isLoaded: true,
            error
          });
        }
      )

}

  render() {


    const { error, isLoaded, candidati_sindaci,avvisi,data,ora } = this.state;
	const classes = this.props;
	if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
		Array.prototype.sortOn = function(key){
			this.sort(function(a, b){
				if(a[key]!=b[key]){
					return b[key]-a[key];
				}else{
					if(a["nome"] > b["nome"]){
						return 1;
					}else if(a["nome"] < b["nome"]){
						return -1;
					}
				}
				return 0;
			});
		}
		candidati_sindaci.sortOn("voti");
      return (
		<Container maxWidth="md">
			<Grid container spacing={2} style={{marginTop:10}} align="center" justify="center" alignItems="center">
				<Grid item xs={12} md={12}>
						<img src = "https://www.scrutiniolive.it/img/logo.png" width="30%" style={{borderRadius:"5%"}}/>
				</Grid>
				<Grid item xs={12} md={12} alignItems="center" justify="center">
					<Typography  variant="h5" component="h2"  style={{textAlign: "center",marginTop:0}}>
					  Scrutinio elezioni amministrative 2022
					</Typography>
					<Typography variant="body2" color="textSecondary" component="p"  style={{textAlign: "center"}}>
					  Comune di Alberobello
					</Typography>
				</Grid>
			</Grid>
				<Divider />
				<Paper  style={{marginTop:0}}>
					{
						avvisi!==""
						?
						<span>
							<Typography style={{marginTop:50,textAlign:"center",textDecoration:"underline",padding:10}}>
								{avvisi}
							</Typography>
							<Divider/>
						</span>
						:
						<span></span>
					}
					<Typography style={{textAlign:"center",padding:10}}>
						{data} - {ora}
					</Typography>
				</Paper>
				<Divider />
			<Grid container spacing={2}  style={{marginTop: 10}}>
			  {candidati_sindaci.map(candidato => (
				<Grid item xs={12} md={4}>
				 <CardMedia1 candidato={candidato}/>
				</Grid>
			  ))}
			</Grid>
			<Paper  style={{marginTop:50}}>
				<Typography style={{marginTop:50,textAlign:"center",textDecoration:"underline",padding:10}}>
					I dati sono ufficiosi e raccolti da volontari che presidiano il seggio elettorale. Per l'ufficialità è necessario attenersi ai dati ministeriali.
				</Typography>
				<Divider />
				<Typography style={{marginTop:10,textAlign:"center",fontStyle:"italic",padding:10}}>
					Powered By <a href="mailto:antoniod987@gmail.com">Antonio De Giorgio</a> & <a href="https://manciniagency.it/" target="_blank">Federica Mancini</a>
				</Typography>
			</Paper>
		 </Container>
		 
      );
    }
  }
}

export default (CandidatiSindaci3)