import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import { blue } from '@material-ui/core/colors';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';

//import waveImg from "./wave.png";
import axios from 'axios';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  root: {
    maxWidth: 345
  },
  media: {
    height: "100px"
  }
});

function MyDialog(props) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog 
		TransitionComponent={Transition}
		keepMounted
		onClose={handleClose} 
		open={open}
		aria-describedby="alert-dialog-slide-description"
	>
      <DialogTitle>Dettaglio candidati</DialogTitle>
      <DialogContent>
			 <List>
				  {selectedValue.map(dettaglio => (
					<ListItem >
						<ListItemText primary={dettaglio.nome + " : " + dettaglio.voti} />
					</ListItem>
				  ))}
				
			</List>
      </DialogContent>
	  <Button onClick={handleClose}>Chiudi dettaglio</Button>
    </Dialog>
  );
}

MyDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  
};
const dettaglioVoti=[{nome:"",voti:""}]
export default function ImgMediaCard(candidato) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(dettaglioVoti);
  
	const handleClickOpen = (id) => 
  {
	  
	axios.post("https://www.scrutiniolive.it/business/getDettaglioCandidati.php",  JSON.stringify({id:id}), 
		{ headers: { "Content-Type": "application/json; charset=UTF-8" },params: { id: id }
		  //Add userID as a param 
		})
		  .then(
			(result) => {
			//   console.log(result.data);
			   setSelectedValue(result.data.dettaglio_voti);
			   console.log(selectedValue);
			   setOpen(true);
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
			console.log(error);
			}
		  )
   
  };
  
  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };
  
  return (
    <Card className={classes.root} key={candidato.candidato.id}>
      <CardMedia
        className={classes.media}
        image={candidato.candidato.pathImg}
        title={candidato.candidato.nome}
		component="img"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          VOTI : {candidato.candidato.voti}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
           {candidato.candidato.nome}
		</Typography>
       
      </CardContent>
	 
    </Card>
  );
}
