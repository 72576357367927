import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import { blue } from '@material-ui/core/colors';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

//import waveImg from "./wave.png";
import axios from 'axios';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  root: {
    maxWidth: 345
  },
  media: {
    height: "100px"
  }
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


function a11yProps(index) {
	  return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	  };
	}
function MyDialog(props) {
  const { onClose, selectedValue,selectedValue2, selectedValue3,open } = props;
	const [value, setValue] = React.useState(0);

	  const handleChange = (event, newValue) => {
		setValue(newValue);
	  };
  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };
  
  return (
    <Dialog 
		TransitionComponent={Transition}
		keepMounted
		onClose={handleClose} 
		open={open}
		aria-describedby="alert-dialog-slide-description"
	>
      <DialogTitle>Dettaglio candidati</DialogTitle>
      <DialogContent>
		  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
			<Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
			  <Tab label="Generale" {...a11yProps(0)} />
			  <Tab label="Per seggio" {...a11yProps(1)} />
			</Tabs>
		  </Box>
		  <TabPanel value={value} index={0}>
		   <List>
					  {selectedValue.map(dettaglio => (
					    <div>
							<ListItem >
								<ListItemText
									primary={dettaglio.nome}
									secondary={"Voti:" +dettaglio.voti}
								/>
							</ListItem>
							<Divider />
						</div>
					  ))}
					
				</List>
		  </TabPanel>
		  <TabPanel value={value} index={1}>
				<Grid container spacing={2} style={{marginTop:10}}>
					  {Object.keys(selectedValue2).map((key) => {
						return(
							<Grid item xs={12} md={12}>
								<div>
									<Paper variant="outlined" square elevation={3}>
										<Typography component="h6" style={{color: 'blue',textAlign:"center"}}>
												Seggio : {key}  -  Voti Sindaco : {selectedValue3[key]}
										</Typography>
										<List>
											{selectedValue2[key].map((dettaglio2)=>{
												 return (
												<ListItem >
													<ListItemText
														primary={dettaglio2.nome}
														secondary={"Voti:" +dettaglio2.voti}
													/>
												</ListItem>
												)
											})}
										</List>
									</Paper>
									<Divider />
								</div>
							</Grid>
						)
					  })}
					  
				</Grid>	
				
		  </TabPanel>
		 
      </DialogContent>
	  <Button onClick={handleClose}>Chiudi dettaglio</Button>
    </Dialog>
  );
}

MyDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  
};
const dettaglioVoti=[{nome:"",voti:""}]
const dettaglioVotiSeggio=[]
const dettaglioVotiSeggioSindaco=[]

export default function ImgMediaCard(candidato) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(dettaglioVoti);
  const [selectedValue2, setSelectedValue2] = React.useState(dettaglioVotiSeggio);
  const [selectedValue3, setSelectedValue3] = React.useState(dettaglioVotiSeggioSindaco);
	const handleClickOpen = (id) => 
  {
	
	axios.post("https://www.scrutiniolive.it/business/getDettaglioCandidati.php",  JSON.stringify({id:id}), 
		{ headers: { "Content-Type": "application/json; charset=UTF-8" },params: { id: id }
		  //Add userID as a param 
		})
		  .then(
			(result) => {
				Array.prototype.sortOn = function(key){
						this.sort(function(a, b){
							if(a[key]!=b[key]){
								return b[key]-a[key];
							}else{
								if(a["nome"] > b["nome"]){
									return 1;
								}else if(a["nome"] < b["nome"]){
									return -1;
								}
							}
							return 0;
						});
					}  
			//   console.log(result.data);
			   result.data.dettaglio_voti.sortOn("voti");
			   setSelectedValue(result.data.dettaglio_voti);
			   Object.keys(result.data.dettaglio_voti_seggio).map((key) =>{
					result.data.dettaglio_voti_seggio[key].sortOn("voti");
			   });
			   setSelectedValue2(result.data.dettaglio_voti_seggio);
			   setSelectedValue3(result.data.dettaglio_voti_seggio_sindaco);
			   console.log(selectedValue2);
			   setOpen(true);
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
			console.log(error);
			}
		  )
   
  };
  
  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };
  
  return (
    <Card className={classes.root} key={candidato.candidato.id}>
      <CardMedia
        className={classes.media}
        image={candidato.candidato.pathImg}
        title={candidato.candidato.nome}
		component="img"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          VOTI : {candidato.candidato.voti}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
           {candidato.candidato.nome}
		</Typography>
		   <Button variant="outlined" onClick={() =>handleClickOpen(candidato.candidato.id)}>
			Dettaglio candidati
		   </Button>
		   <MyDialog
			selectedValue={selectedValue}
			selectedValue2={selectedValue2}
			selectedValue3={selectedValue3}
			open={open}
			onClose={handleClose}
		  />
       
      </CardContent>
	 
    </Card>
  );
}
