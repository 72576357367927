import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";

import Typography from "@material-ui/core/Typography";
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import { blue } from '@material-ui/core/colors';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import CardMedia1 from "./CardMedia";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Paper from '@material-ui/core/Paper';
import {useParams,useNavigate,Navigate} from "react-router-dom";
import App from './App';

import base64 from 'react-native-base64'


//import waveImg from "./wave.png";
import axios from 'axios';

function GetPrivateRappresentante() {

    const {token } = useParams();
	const decoded = base64.decode(token);
	var tokenDec= decoded.substr(5,decoded.length-10);

    console.log(tokenDec);
	//console.log(base64.decode(token));
    return (
        <div>
            <PrivateRappresentate token={tokenDec} />
        </div>
    );
}


class PrivateRappresentate extends React.Component {
  
 
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      candidati_sindaci: [],
      consigliereScelto:{},
	  disabled:false,
	  loading:false,
	  count:0,
	  console:"",
	  id_sindacoRef:3,
	  token:"",
	  errorAut:"",
	  seggio:""
    };

	
  }
	
  componentDidMount() {
	  Promise.all([axios.post("https://www.scrutiniolive.it/business/verifyToken.php",  JSON.stringify({token:this.props.token}), 
			{ headers: { "Content-Type": "application/json; charset=UTF-8" },params: { token:this.props.token},async: false
			  //Add userID as a param 
			})
			  .then(
			(result) => {
			//   console.log(result.data);
				if(result.data.success){
					 setInterval(() => this.getCandidati(), 1000);
				//	  setInterval(() => {this.setState({console:""})}, 6000);
					  this.getLista();
					  
					  this.setState({
						token:this.props.token,
						seggio:result.data.seggio
					  });
				}else{
					this.setState({
						errorAut:"Token di autenticazione non valido",
						isLoaded:true
					});
				}
			  
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
				console.log(error);
				this.setState({loading: false});
			}
			)]);
	 }
	 
  handleAdd = (id, name) => 
	  {
		  
	//	 const { consigliereScelto } = this.state;
		if(this.state.loading){
			return
		}else{
				this.setState({loading: true});
		  
		  Promise.all([axios.post("https://www.scrutiniolive.it/business/addPreferenza.php",  JSON.stringify({id:id,token:this.state.token}), 
			{ headers: { "Content-Type": "application/json; charset=UTF-8" },params: { id:id,token:this.state.token},async: false
			  //Add userID as a param 
			})
			  .then(
			(result) => {
			//   console.log(result.data);
				
			   this.setState({
				isLoaded: true,
				loading:false,
				console: "Preferenza aggiunta per il/la candidato/a : " + name
			  });
			  this.getCandidati();
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
				console.log(error);
				this.setState({loading: false});
			}
			)])
		}
	  }
  getCandidati(){
	/*fetch("https://www.scrutiniolive.it/business/getCandidati.php",{
	  crossDomain:true,
	  method: 'GET',
	  headers: {'Content-Type':'application/json'},
//	  body: JSON.stringify({
//		username: user,
//		password: pass,
//	  })

	})*/
	var config = {
		  method: 'post',
		  url: 'https://www.scrutiniolive.it/business/getCandidati.php',
		   crossDomain:true,
		  headers: { 
			'Content-Type': 'application/json'
		  },
		  data : {}
		};
	 axios(config)
      .then(
        (result) => {
		 Array.prototype.sortOn = function(key){
			this.sort(function(a, b){
				if(a[key]!=b[key]){
					return b[key]-a[key];
				}else{
					if(a["nome"] > b["nome"]){
						return 1;
					}else if(a["nome"] < b["nome"]){
						return -1;
					}
				}
				return 0;
			});
		}
		result.data.candidati_sindaci.sortOn("voti");
         this.setState({
            isLoaded: true,
            candidati_sindaci: result.data.candidati_sindaci
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
		this.setState({
            isLoaded: true,
            error
          });
        }
      )

	
  }
  
 
	addPreferenza(){
		   
	}
	
	delPreferenza(id,name){
		  axios.post("https://www.scrutiniolive.it/business/delPreferenza.php",  JSON.stringify({id:id,token:this.state.token}), 
			{ headers: { "Content-Type": "application/json; charset=UTF-8" },params: { id:id,token:this.state.token }
			  //Add userID as a param 
			})
			  .then(
				(result) => {
				//   console.log(result.data);
				   this.setState({
					isLoaded: true,
					console: "Preferenza eliminata per il/la candidato/a : " + name
				  });
				   this.getCandidati();
				},
				// Note: it's important to handle errors here
				// instead of a catch() block so that we don't swallow
				// exceptions from actual bugs in components.
				(error) => {
				console.log(error);
				}
			  )
	}
	
	getLista(){
		/*fetch("https://www.scrutiniolive.it/business/getCandidati.php",{
		  crossDomain:true,
		  method: 'GET',
		  headers: {'Content-Type':'application/json'},
	//	  body: JSON.stringify({
	//		username: user,
	//		password: pass,
	//	  })

		})*/

		  
		  axios.post("https://www.scrutiniolive.it/business/getLista.php",  JSON.stringify({id_sindaco:this.state.id_sindacoRef,token:this.state.token}), 
			{ headers: { "Content-Type": "application/json; charset=UTF-8" },params: { id_sindaco:this.state.id_sindacoRef,token:this.state.token }
			  //Add userID as a param 
			})
			  .then(
				(result) => {
				//   console.log(result.data);
				   this.setState({
					isLoaded: true,
					lista: result.data.lista
				  });
				},
				// Note: it's important to handle errors here
				// instead of a catch() block so that we don't swallow
				// exceptions from actual bugs in components.
				(error) => {
				console.log(error);
				}
			  )

	}

  render() {

	//const {token} = useParams();
	
    const { error, isLoaded, candidati_sindaci,lista,consigliereScelto,console,errorAut } = this.state;
	const classes = this.props;

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
		
	 if(errorAut==""){
		  return (
			<div>
				<Paper variant="outlined" square elevation={3}>
						<Typography  variant="h5" component="h2" style={{textAlign: "center"}}>
						  Scrutinio elezioni amministrative 
						</Typography>
						<Typography variant="body2" color="textSecondary" component="p"  style={{textAlign: "center"}}>
						  Comune di Alberobello 2022
						</Typography>
				</Paper>
				<Paper variant="outlined" square elevation={3}>
						<Typography  variant="h5" component="h2" style={{textAlign: "center",textStyle:"underlined"}}>
						  Seggio numero {this.state.seggio} 
						</Typography>
						
				</Paper>
			<Grid container spacing={2} style={{marginTop:10}}>
			  
			<Grid item xs={8} md={4}>
				  <Container maxWidth="md">
						
					<Grid container spacing={1}>
					  {candidati_sindaci.map(candidato => (
						<Grid item xs={12} md={12} key={candidato.id}>
						 <CardMedia1 candidato={candidato}/>
						</Grid>
					  ))}
					</Grid>
				 </Container>
			 </Grid>
			 <Grid item xs={8} md={8}  style={{marginTop:10}}>
				 <Container maxWidth="md" style={{marginTop:10}}>
						<Paper variant="outlined" square elevation={3}>
								<Typography component="h5">
									{this.state.console}
								</Typography>
									
						 </Paper>
				</Container>			   
				 <Container maxWidth="md" style={{marginTop:10}}>
						<Card>
							<CardHeader>
								<Typography component="h3">
									  Aggiungi preferenze
								</Typography>
							</CardHeader>
							<CardContent>
								<Grid container spacing={2}>
					
									{lista.map(consigliere => (
									<Grid item xs={8} md={4} key={consigliere.id}>
										<Grid container>
										<Grid item>{consigliere.nome} </Grid>
										</Grid>
										<Grid container>
										<Grid item>
											<IconButton onClick={()=>this.handleAdd(consigliere.id,consigliere.nome)}>
												<AddIcon />
											</IconButton>
										</Grid>
										<Grid item>
											<IconButton onClick={() =>{if (window.confirm('Confermare la cancellazione di una preferenza per '+consigliere.nome+' ?')) this.delPreferenza(consigliere.id,consigliere.nome)}}>
												<DeleteIcon />
											</IconButton>
										</Grid>
										</Grid>
									</Grid>
									
								  ))}
									</Grid>
							</CardContent>
						 </Card>
				 </Container>
				 </Grid>
				</Grid>
			 </div>
		  );
		}else{
			return(<div><Container><Typography component="h5">
									{errorAut}
								</Typography></Container></div>);
		}
	}
  }
}
export default GetPrivateRappresentante